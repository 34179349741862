import { faHouseHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FaAlgolia, FaBars, FaBong, FaBook, FaComments, FaDna, FaEye, FaFile, FaGlasses, FaGlobeAsia, FaKey, FaLandmark, FaPaperclip, FaQuestion, FaSchool } from 'react-icons/fa';
import { MdOutlinePermMedia, MdWork } from 'react-icons/md';

import { useFeatureFlag } from '../../../feature-flags/use-feature-flag';

type IconProps = { fontSize?: string | number; color?: string };

export const useNavigationAuthoringItems = () => {
    const isTagCreationEnabled = useFeatureFlag('learnosity-statistics-enabled');

    return [
        { title: 'Content Authoring' },
        { title: `Build number: ${window._env_.BUILDKITE_BUILD_NUMBER}` },
        { title: `Environment: ${window._env_.ENVIRONMENT.toUpperCase()}` },
        { title: 'Home', icon: (props: IconProps) => <FontAwesomeIcon {...props} icon={faHouseHeart} />, routeTo: '/' },
        { title: 'Questions', icon: FaQuestion, routeTo: '/questions' },
        { title: 'Question Sets', icon: FaComments, routeTo: '/question-sets' },
        { title: 'Units of Work', icon: MdWork, routeTo: '/units-of-work' },
        { title: 'Courses', icon: FaSchool, routeTo: '/courses' },
        { title: 'Content Items', icon: MdOutlinePermMedia, routeTo: '/content-items' },
        { title: 'Strand', icon: FaDna, routeTo: '/strand' },
        { title: 'Outcome code', icon: FaBong, routeTo: '/outcome-code' },
        { title: 'CAT Questions', icon: FaAlgolia, routeTo: '/cat-questions' },
        { title: 'CAT Snapshots', icon: FaGlasses, routeTo: '/cat-snapshots' },
        { title: 'Reports' },
        ...(isTagCreationEnabled ? [{ title: 'CAT Statistics', icon: FaBars, routeTo: '/cat-statistics' }] : []),
        { title: 'CAT Validator', icon: FaPaperclip, routeTo: '/cat-validator' },
        { title: 'CAT Search', icon: FaGlasses, routeTo: '/cat-question-search' },
        { title: 'CAT Snapshots', icon: FaGlasses, routeTo: '/cat-snapshots' },
        { title: 'Content Validator', icon: FaEye, routeTo: '/content-validator' },
        { title: 'Course Manager' },
        { title: 'Default Course', icon: FaFile, routeTo: '/default-course' },
        { title: 'Settings' },
        { title: 'Countries', icon: FaLandmark, routeTo: '/settings/countries' },
        { title: 'Locales', icon: FaGlobeAsia, routeTo: '/settings/locales' },
        { title: 'Login Credential', icon: FaKey, routeTo: '/settings/login-credential' },
        { title: 'Data Dictionary', icon: FaBook, routeTo: '/settings/data-dictionary' }
    ];
};
