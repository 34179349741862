import { Button, Radio, RadioGroup } from '@chakra-ui/react';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import uniqBy from 'lodash.uniqby';
import React, { useMemo, useState } from 'react';
import { UseMutateFunction } from 'react-query';

import CurriculumVersionDropdown from './curriculum-version-dropdown';
import AccordionComponent from '../base/accordion/accordion';
import TableComponent from '../base/table/table-component';
import { MasterCourseDto, MasterCourseHierarchyDto, MasterCourseHierarchyGetInput } from '../curriculum/interface';

interface CurriculumMasterCourseSelectorProps {
    masterCourseList: MasterCourseDto[] | undefined;
    selectedMasterCourseId: string | undefined;
    setSelectedMasterCourseId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSelectedMasterCourseName: React.Dispatch<React.SetStateAction<string | undefined>>;
    masterCourseHierarchy: MasterCourseHierarchyDto | undefined;
    isSectionsLoading: boolean;
    getMasterCourseHierarchy: UseMutateFunction<MasterCourseHierarchyDto | undefined, unknown, MasterCourseHierarchyGetInput, unknown>;
    setSelectedMasterCourseVersion: React.Dispatch<React.SetStateAction<number | undefined>>;
    selectedMasterCourseVersion: number | undefined;
}

const CurriculumMasterCourseSelector: React.FC<CurriculumMasterCourseSelectorProps> = ({
    isSectionsLoading,
    getMasterCourseHierarchy,
    masterCourseList,
    selectedMasterCourseId,
    setSelectedMasterCourseId,
    setSelectedMasterCourseVersion,
    setSelectedMasterCourseName,
    selectedMasterCourseVersion
}) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const masterCourseColumns = useMemo<ColumnDef<MasterCourseDto>[]>(
        () => [
            {
                accessorKey: 'courseName',
                id: 'courseName',
                header: 'Course Name',
                enableSorting: false,
                cell: ({ row }) => (
                    <Radio
                        onChange={() => {
                            setSelectedMasterCourseName(row.original.courseName);
                            setSelectedMasterCourseVersion(row.original.version);
                        }}
                        value={row.original.masterCourseId}
                    >
                        {row.original.courseName}
                    </Radio>
                )
            },
            {
                accessorKey: 'version',
                id: 'version',
                header: 'Course Version',
                enableSorting: false,
                cell: ({ row }) => (
                    <CurriculumVersionDropdown
                        setSelectedMasterCourseVersion={setSelectedMasterCourseVersion}
                        maxVersion={row.original.version}
                        selectedVersion={selectedMasterCourseVersion}
                        isDisabled={row.original.masterCourseId !== selectedMasterCourseId}
                    />
                )
            }
        ],
        [selectedMasterCourseId, selectedMasterCourseVersion, setSelectedMasterCourseName, setSelectedMasterCourseVersion]
    );
    const highestMasterCourseVersions: Record<string, number> = {};

    masterCourseList?.forEach((item) => {
        const { masterCourseId, version } = item;

        if (!highestMasterCourseVersions[masterCourseId] || version > highestMasterCourseVersions[masterCourseId]) {
            highestMasterCourseVersions[masterCourseId] = version;
        }
    });

    const filteredMasterCourses = masterCourseList?.filter((item) => item.version === highestMasterCourseVersions[item.masterCourseId]);

    return (
        <AccordionComponent isClosedOnRender={!!masterCourseList} title={'2. Select a master course'}>
            <RadioGroup
                onChange={(value) => {
                    setSelectedMasterCourseId(value);
                }}
                value={selectedMasterCourseId}
            >
                {filteredMasterCourses ? (
                    <TableComponent columns={masterCourseColumns} data={uniqBy(filteredMasterCourses, (obj) => obj.masterCourseId)} sorting={sorting} setSorting={setSorting} />
                ) : (
                    <div>No master courses found</div>
                )}
            </RadioGroup>
            {selectedMasterCourseId && (
                <Button
                    isLoading={isSectionsLoading}
                    mt={3}
                    onClick={() => {
                        getMasterCourseHierarchy({
                            masterCourseId: selectedMasterCourseId,
                            version: selectedMasterCourseVersion
                        });
                    }}
                >
                    Fetch sections
                </Button>
            )}
        </AccordionComponent>
    );
};

export default CurriculumMasterCourseSelector;
